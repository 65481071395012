<template>
  <v-card class="pa-2">
    <v-card-title class="pb-2">
      <span>{{ $lang.labels.name }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row wrap no-gutters justify="space-between" class="pb-1">
        <v-col
          cols="12"
          class="pr-sm-1"
        >
          <v-text-field
            v-model="newName"
            outlined
            dense
            :label="$lang.labels.name"
            required
            :rules="[v => !!v || $lang.labels.required]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters wrap justify="end">
        <v-col
          cols="6"
          class="text-left"
        >
          <div class="d-inline-flex align-center">
            <v-btn
              class="button-default-width"
              color="secondary"
              @click="$emit('closeDialog', true)"
            >
              <v-icon
                right
                dark
                class="ma-0"
              >
                mdi-arrow-left
              </v-icon>
              <span class="ml-1">{{ $lang.actions.back }}</span>
            </v-btn>
            <action-button-with-confirmation
              :action-text="$lang.actions.areYouSureYouWantToDelete"
              :action-text-suffix="newName"
              :title="$lang.actions.delete"
              type="role"
              :is-disabled="!userCanDelete || item.isSystem"
              :button-text="$lang.actions.delete"
              :button-color="'error'"
              :data-cy="'role-delete'"
              :forced-option="true"
              :trigger-force-logic="triggerForceLogic"
              :regular-delete-errors-usages="regularDeleteErrorsUsages"
              :regular-delete-errors-resources="regularDeleteErrorsResources"
              :regular-delete-errors-users="regularDeleteErrorsUsers"
              class="ml-2"
              :delete-success="deleteSuccess"
              @submit="deleteRoleFunct($event)"
              @closeDialog="''"
              @closeAfterDelete="$emit('closeDialog', true)"
            />
          </div>
        </v-col>
        <v-col
          cols="6"
          class="text-right"
        >
          <v-btn
            :disabled="!valid"
            color="success"
            class="button-default-width mr-1"
            @click="submit()"
          >
            {{ $lang.actions.submit }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { deleteRoleUsingDELETE as deleteRole } from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: { ActionButtonWithConfirmation },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valid: false,
      newName: '',
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      regularDeleteErrorsResources: [],
      regularDeleteErrorsUsers: []
    }
  },
  created() {
    this.newName = this.item.name

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'ROLE_DELETER') || this.isSuperUser
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    deleteRoleFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteRole({ id: this.item.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
            this.regularDeleteErrorsResources = res.response.data.data.relatedResources
            this.regularDeleteErrorsUsers = res.response.data.data.relatedUsers
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    submit() {
      this.$emit('update', this.newName)
    }
  }
}
</script>
<style lang="scss" scoped>
.to-select:hover {
  color: var(--v-primary-base) !important;
}
</style>
